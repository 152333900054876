import { functions } from '@wap-client/core'

import type { SpinnerProps, SpinnerSize } from './types'

export const sizes: { [K in SpinnerSize]: number } = {
  xsmall: 16,
  small: 24,
  medium: 32,
  large: 40,
  xlarge: 48,
}

const Spinner: React.FunctionComponent<SpinnerProps> = ({
  className,
  size = 'medium',
  ...rest
}) => {
  const classNames = functions.classnames('spinner', className)
  const currentSize = typeof size === 'number' ? size : sizes[size]

  return (
    <div className={classNames} {...rest}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
          opacity=".25"
        />
        <circle cx="12" cy="2.5" r="1.5" fill="currentColor">
          <animateTransform
            attributeName="transform"
            dur="0.75s"
            repeatCount="indefinite"
            type="rotate"
            values="0 12 12;360 12 12"
          />
        </circle>
      </svg>
    </div>
  )
}

export default Spinner
