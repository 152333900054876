import React, { useRef, useState } from 'react'
import {
  clamp,
  cubicBezier,
  motion,
  useScroll,
  useTransform,
} from 'framer-motion'

import { queryService } from '@wap-client/services'
import { functions, useApp } from '@wap-client/core'

import { Column, Container, Row } from '@/components/base/gridview'
import Button from '@/components/base/button/Button'
import Icon from '@/components/base/icon'
import Spinner from '@/components/base/spinner'

import { Subs, SubscribeFormTypes, SubscribeProps } from './types'

const Subscribe: React.FunctionComponent<SubscribeProps> = ({
  title,
  desc,
}) => {
  const app = useApp()

  const [formStatus, setFormStatus] = useState<SubscribeFormTypes>('default')
  const [loadingState, setloadingState] = useState(false)
  const [formValues, setFormValues] = useState<any>({})
  const [formErrorValues, setFormErrorValues] = useState<any>({
    email: '',
  })

  const validateForm = () => {
    const errors: any = {}

    if (!formValues.email) {
      errors.email = app.settings.translations['errorsEmail']
    } else {
      errors.email = ''
    }

    setFormErrorValues(errors)
    return Object.values(errors).every((error) => error === '')
  }

  const closeStatus = () => {
    setFormStatus('default')
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    const { email } = e.target.elements

    setFormValues({
      email: email.value,
    })

    if (!validateForm()) {
      return
    }

    setloadingState(true)

    const requestBody = {
      parameters: null,
      columns: [
        {
          name: 'eMail',
          data: {
            refs: [],
            value: formValues.email,
          },
        },
        {
          name: 'baslik',
          data: {
            refs: [],
            value: 'Abone',
          },
        },
      ],
    }

    queryService
      .create(
        app.environment,
        {
          name: 'create-app-kty-aboneler',
          language: app.language,
        },
        requestBody
      )
      .then((response) => {
        if (response) {
          setFormStatus('success')
          setTimeout(() => {
            setFormValues({
              email: '',
            })
          }, 2000)
        }
        setloadingState(false)
      })

      .catch((error) => {
        if (error.code === 409) {
          setFormStatus('duplicate')
        } else {
          setFormStatus('error')
        }
        setloadingState(false)
      })
  }

  const targetRef = useRef<HTMLDivElement>(null)

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start center', 'center end'],
  })

  const rotateX = useTransform(scrollYProgress, [0, 1], [0, 25])
  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85])

  return (
    <section className="subscribe" ref={targetRef}>
      <Container size="narrow">
        <Row xs={{ justify: 'center' }}>
          <Column auto>
            <div className="subscribe__form">
              <motion.div
                className="subscribe__form__in"
                style={{ rotateX: rotateX, scale: scale }}
              >
                <div className="subscribe__form__in__title">{title}</div>
                {desc && (
                  <div
                    className="subscribe__form__in__desc"
                    dangerouslySetInnerHTML={{ __html: desc }}
                  />
                )}

                <form
                  onSubmit={onSubmit}
                  className="subscribe__form__in__group"
                >
                  <label
                    className={functions.classnames(
                      'error__message',
                      formErrorValues.email && 'error__message--active'
                    )}
                  >
                    {formErrorValues.email}
                  </label>
                  <input
                    type="email"
                    value={formValues.email}
                    name="email"
                    onChange={(e) => {
                      setFormValues({ ...formValues, email: e.target.value })
                      setFormErrorValues({ ...formErrorValues, email: '' })
                    }}
                    placeholder={app.settings.translations['email']}
                    required
                  />
                  <Button type="submit">
                    {loadingState ? (
                      <>
                        {app.settings.translations['sending']} <Spinner />
                      </>
                    ) : (
                      <>{app.settings.translations['signUp']}</>
                    )}
                  </Button>
                  <div
                    className={functions.classnames(
                      'subscribe__form__in__group__alert',
                      `subscribe__form__in__group__alert--${formStatus}`,
                      !(formStatus === 'default') &&
                        `subscribe__form__in__group__alert--active`
                    )}
                  >
                    {app.settings.translations[`form-${formStatus}`]}
                    <Button onClick={() => closeStatus()}>
                      <Icon name="close" />
                    </Button>
                  </div>
                </form>
              </motion.div>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

export default Subscribe
